import axios from "axios";
import router from "@/router/routes";
import store from '@/store';

export default function auth({ to, from, next }) {
  var token = localStorage.getItem("token");
  var USER = JSON.parse(localStorage.getItem("user"))
  var isAuthenticatedToken = Boolean(token);
  var isAuthenticatedUser = Boolean(USER);

  if (isAuthenticatedToken && isAuthenticatedUser) {
    if (store.getters.auth) {
      next();
    } else {
      axios
        .get(axios.defaults.baseURL + "user/getuserbyid/" +  USER.id,{
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
          const Response = response.data

          if (Response.role_id == "2" || Response.role_id === 2) {
            let user = {

              address: Response.address,
              avatar: Response.avatar,
              created_at: Response.created_at,
              email: Response.email,
              id: Response.id,
              name: Response.name,
              phone: Response.phone,
              role_id: Response.role_id,
              updated_at: Response.updated_at,
              token: token,

            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            localStorage.setItem("user", JSON.stringify(user));
            // if(user.roles === 2){
            //   router.push("/company")
            //  }
          }

          axios.get(axios.defaults.baseURL + 'get_transaction_by_userid/' + USER.id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
          })
          .then((response) => {
            if(typeof response.data.message == 'string'){
              store.commit("SET_USER_TRANSACTION_HISTORY", []);
            }else{
              store.commit("SET_USER_TRANSACTION_HISTORY", response);
            }
          })
          .catch((error) => {
          })
          axios.get(axios.defaults.baseURL + 'getinquiriesbyuserid/' + USER.id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
          })
          .then((res) => {
            if(Array.isArray(res.data)){
              store.commit('SET_Enquiry_BY_USER_ID', res.data)
            }
            else{
               store.commit('SET_Enquiry_BY_USER_ID', res.data.message)
            }
          })
          .catch((error) => {
            console.log("error from get_transaction_by_userid--", error);
          })
          next();
        })
        .catch((err) => {
          if(err?.response?.statusText == "UNAUTHORIZED"){
            if(err.response?.data?.ERROR == 'Signature has expired' || err.response?.data?.ERROR == 'Not enough segments'){
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              next({name: "LoginPage"})
              if(confirm('Your login session has been expired. Click OK to login again to access your account.')){
                router.push("/login-user")
                // next({name: "LoginPage"})
              }else{
                router.push("/")
                // next({name: "HomePage"})
              }
            }
          }
          if (err.message == "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            router.push("/login-user")
          }
          if (err?.response?.data?.msg == "Token has expired") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            router.push("/")
          }
        });
    }
  } else {
    next();
  }
}
