// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from './apis/user';
// import router from "../router/routes";
import store from '@/store';

const state = {
    // Additional state if needed
    userTransationHistory: null,
}

const mutations = {
    SET_USER_TRANSACTION_HISTORY(state, data) {
      console.log("userTransationHistory set", data.data);
      state.userTransationHistory = data.data.reverse();
    },
}

const actions = {
    // Additional actions if needed
      getAllTransactionsHistory({ state, commit }, payload) {
        console.log('getAllTransactionsHistory !! state:--', state);
        console.log('getAllTransactionsHistory !! payload:--', payload);
        console.log('getAllTransactionsHistory !! store:--', store);
        const user = JSON.parse(localStorage.getItem('user'))
        console.log('getAllTransactionsHistory !! user:--', user);
        
            // return
            // return new Promise((resolve, reject) => {
                axios.get(axios.defaults.baseURL + 'get_transaction_by_userid/' + user.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  })
                  .then((response) => {
                    console.log("response from get_transaction_by_userid--", response);
                    commit("SET_USER_TRANSACTION_HISTORY", response);
                  })
                  .catch((error) => {
                    console.log("error from get_transaction_by_userid--", error);
                  })
            // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };