import { createStore } from 'vuex';
// import App from "../../src/app.vue";
//auth and toast
import auth from './auth.js';
import VerifyErrorsAndToasts from './verifyErrorsAndToasts.js';
import Enquiries from './module/user/enquiries.js';
import Blogs from './module/user/blog.js';
import Responses from './responses.js';
import transaction from './transaction.js';

//admin
// import dashboard from './module/admin/dashboard.js';


export default createStore({
  modules: {
    // auth and toast
    auth,
    VerifyErrorsAndToasts,
    Enquiries,
    Blogs,
    Responses,
    transaction,
  },
});