// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/user';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    allEnquiries: '',
    limitedEnquiry: '',
    enquiryByUserId: '',
    enquiryById: '',

    allResponses: '',
    limitedResponses: '',

    singleEnquery: '',
    responseByUserId: '',
    responseById: '',

    userDetails: '',
    getRewards: '',
    getRecentFinds: '',
}
// function tryParseJSON(jsonString) {
//     console.log('tryParseJSON 1-', jsonString);
//     try {
//         const parsed = JSON.parse(jsonString);
//         // Check if the parsed result is an object or array (valid JSON structures)
//         if (parsed && typeof parsed === 'object') {
//             console.log('parsed 3-', parsed);
//             return parsed;
//         }
//     } catch (e) {
//         console.log('e -', e);
//         // If parsing fails, return the original string
//     }
//     console.log('tryParseJSON 2-', jsonString);
//     return jsonString;
// }

const mutations = {
    SET_ALL_ENQUIRIE_DATA(state, data) {
        if(Array.isArray(data)){
            const array = []
        data.forEach(element => {
        array.push(
            {...element,
                origin: JSON.parse(element?.origin) || '',
                destination: JSON.parse(element?.destination) || '',
            })
    
        });
            state.allEnquiries = array
        }
        else{
            state.allEnquiries = data
        }
  },
  SET_LIMITED_ENQUIRY_DATA(state, data) {
    console.log('SET_Enquiry_BY_USER_ID', data);
     if(Array.isArray(data)){
        const array = []
        data.forEach(element => {
        array.push(
            {...element,
                // origin: tryParseJSON(element?.origin),
                // destination: tryParseJSON(element?.destination),
                origin: JSON.parse(element?.origin) || '',
                destination: JSON.parse(element?.destination) || '',
            })

        });
            state.limitedEnquiry = array
     }
     else{
        state.limitedEnquiry = data
     }
    //   state.limitedEnquiry = data
  },
  SET_Enquiry_BY_USER_ID(state, data) {
    console.log('SET_Enquiry_BY_USER_ID', data);

    // state.enquiryByUserId = data
    if(Array.isArray(data)){
        const array = []
    data.forEach(element => {
    array.push(
        {...element,
            origin: JSON.parse(element?.origin) || '',
            destination: JSON.parse(element?.destination) || '',
        })

    });
        state.enquiryByUserId = array
    }
    else{
        state.enquiryByUserId = data
    }
  },
  SET_ENQUIRY_BY_ID(state, data) {
      state.enquiryById = data
  },
  SET_ALL_RESPONSES_DATA(state, data) {
      state.allResponses = data
  },
  SET_LIMITED_RESPONSES_DATA(state, data) {
      state.limitedResponses = data
  },
    SET_SINGLE_ENQUIRY_DATA(state, data) {
        state.singleEnquery = {...data,
            origin: JSON.parse(data?.origin) || '',
            destination: JSON.parse(data?.destination) || '',
        }
  },
  SET_RESPONSE_BY_USER_ID(state, data) {
    if(Array.isArray(data)){
        const array = []
    data.forEach(element => {
    array.push(
        {...element,
            origin: JSON.parse(element?.origin) || '',
            destination: JSON.parse(element?.destination) || '',
        })

    });
        state.responseByUserId = array
    }
    else{
        state.responseByUserId = data
    }
  },
  SET_RESPONSE_BY_ID(state, data) {
      state.responseById = data
  },

  SET_USER_DETAILS(state, data) {
      state.userDetails = data
  },
  SET_REWARDS(state, data) {
      state.getRewards = data
  },
  SET_RECENT_FIND_DATA(state, data) {
      state.getRecentFinds = data
  },
}

const actions = {
    // Additional actions if needed
      getAllEnquiriesData({ commit }) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_INQUIRIES)
                    .then((res) => {
                        console.log('getAllEnquiriesData---res !!', res)
                        const reversed = res.data.reverse()
                        // console.log('reversed array:---', reversed);
                        
                        if(Array.isArray(res.data)){
                            commit('SET_ALL_ENQUIRIE_DATA', reversed)
                            // const limitedEnquiryData = res.data.slice(0, 3); // Get only the first 3 items
                            commit('SET_LIMITED_ENQUIRY_DATA', reversed.slice(0, 3));
                        }
                        else{
                           commit('SET_ALL_ENQUIRIE_DATA', res.data.message)
                           commit('SET_LIMITED_ENQUIRY_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getAllEnquiriesData err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },
      singleEnqueryData({ commit },payload) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_INQUIRY_BY_ID + payload)
                    .then((res) => {
                        console.log('singleEnqueryData---res !!', res)
                        if(res?.data?.message != "No Data Found"){
                            commit('SET_SINGLE_ENQUIRY_DATA', res.data[0])
                        }
                    })
                    .catch((err) => {
                        console.log('singleEnqueryData err', err)
                    })
            // })
    },

    addEnquiry({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("user_id", payload.user_id);
        formData.append("schedule", payload.schedule);
        formData.append("date_month", payload.date_month);
        formData.append("date_range", payload.date_range);
        formData.append("travelers_no", payload.travelers_no);
        formData.append("origin", JSON.stringify(payload.origin));
        formData.append("destination",JSON.stringify(payload.destination));
        formData.append("budget", payload.budget);
        formData.append("class", payload.travelClass);
        formData.append("reward", JSON.stringify(payload.reward));
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_QUIRIES, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("addenquiry res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    router.push("/all-enquiries")
                })
                .catch((err) => {
                    console.log("addenquiry err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    updateEnquiry({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("id", payload.id);
        formData.append("schedule", payload.schedule);
        formData.append("date_month", payload.date_month);
        formData.append("date_range", payload.date_range);
        formData.append("travelers_no", payload.travelers_no);
        formData.append("origin", JSON.stringify(payload.origin));
        formData.append("destination",JSON.stringify(payload.destination));
        formData.append("budget", payload.budget);
        formData.append("class", payload.travelClass);
        formData.append("reward", JSON.stringify(payload.reward));
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.UPDATE_QUIRIES, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("updateEnquiry res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    router.push("/my-enquiries")
                })
                .catch((err) => {
                    console.log("updateEnquiry err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    getEnquiryyUserId({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_INQUIRY_BY_USER_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('getEnquiryyUserId---res !!', res)
                    if(Array.isArray(res.data)){
                        commit('SET_Enquiry_BY_USER_ID', res.data.reverse())
                    }
                    else{
                       commit('SET_Enquiry_BY_USER_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getEnquiryyUserId err', err)
                })
        // })
    },

    getEnquiryById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_INQUIRY_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('getEnquiryById---res !!', res)
                        commit('SET_ENQUIRY_BY_ID', res.data[0])
                })
                .catch((err) => {
                    console.log('getEnquiryById err', err)
                })
        // })
    },

    getResponsesByInquiryId({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_RESPONSE_BY_INQUIRY_ID + id,{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('getResponsesByInquiryId---res !!', res)
                    if(Array.isArray(res.data)){
                        commit('SET_ALL_RESPONSES_DATA', res.data)
                        const limitedResponseData = res.data.slice(0, 3); // Get only the first 3 items
                        commit('SET_LIMITED_RESPONSES_DATA', limitedResponseData);
                    }
                    else{
                       commit('SET_ALL_RESPONSES_DATA', res.data.message)
                       commit('SET_LIMITED_RESPONSES_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getResponsesByInquiryId err', err)
                })
        // })
    },

    deleteEnquiryById({dispatch }, id) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_INQUIRY_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('deleteEnquiryById---res !!', res)
                    resolve(res)
                    var USER = JSON.parse(localStorage.getItem("user"))
                    dispatch('getEnquiryyUserId', USER.id)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteEnquiryById err', err)
                })
        })
    },

    addResponse({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("user_id", payload.user_id);
        formData.append("inq_id", payload.inq_id);
        formData.append("airline", payload.airline);
        formData.append("book_via", JSON.stringify(payload.book_via));
        formData.append("departure_date", payload.departure_date);
        formData.append("departure_time", payload.departure_time);
        formData.append("arrival_time", payload.arrival_time);
        formData.append("travel_class", payload.travel_class);
        formData.append("flight_numbers", payload.flight_numbers);
        formData.append("point_taxes", payload.point_taxes);
        formData.append("number_of_stops", payload.number_of_stops);
        formData.append("layover_airports", payload.layover_airports);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_RESPONSE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("addResponse res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    router.push("/my-response")
                })
                .catch((err) => {
                    console.log("addResponse err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    updateResponse({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("id", payload.id);
        formData.append("airline", payload.airline);
        formData.append("book_via", JSON.stringify(payload.book_via));
        formData.append("departure_date", payload.departure_date);
        formData.append("departure_time", payload.departure_time);
        formData.append("arrival_time", payload.arrival_time);
        formData.append("travel_class", payload.travel_class);
        formData.append("flight_numbers", payload.flight_numbers);
        formData.append("point_taxes", payload.point_taxes);
        formData.append("number_of_stops", payload.number_of_stops);
        formData.append("layover_airports", payload.layover_airports);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.UPDATE_RESPONSE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log("updateResponse res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    router.push("/my-response")
                })
                .catch((err) => {
                    console.log("updateResponse err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },
    
    getResponseByUserId({ commit }, id) {
        console.log("id", id)
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_RESPONSE_BY_USER_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('getResponseByUserId---res !!', res)
                    if(Array.isArray(res.data)){
                        commit('SET_RESPONSE_BY_USER_ID', res.data.reverse())
                    }
                    else{
                       commit('SET_RESPONSE_BY_USER_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getResponseByUserId err', err)
                })
        // })
    },

    getResponseById({ commit }, id) {
        console.log("id", id)
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_RESPONSE_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('getResponseById---res !!', res)
                    commit('SET_RESPONSE_BY_ID', res.data[0])
                })
                .catch((err) => {
                    console.log('getResponseById err', err)
                })
        // })
    },

    deleteResponseById({dispatch }, id) {
        console.log("id", id)
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_RESPONSE_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log('deleteResponseById---res !!', res)
                    resolve(res)
                    var USER = JSON.parse(localStorage.getItem("user"))
                    dispatch('getResponseByUserId', USER.id)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteResponseById err', err)
                })
        })
    },

    getUserByIdForProfile({ dispatch, commit }, id) {

          return new Promise((resolve, reject) => {
            axios
              .get(axios.defaults.baseURL + apis.GET_USER_BY_ID + id,{
                herders: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((res) => {
                console.log("getUserByIdForProfile res--", res);
                commit("SET_USER_DETAILS", res.data);
    
                resolve(res);
              })
              .catch((err) => {
                console.log("getUserByIdForProfile err", err);
                dispatch("checkErrorAndSendToast", [err.response, "error"]);
                reject(err);
              });
          });
      },

    getRewards({ dispatch, commit }) {

          return new Promise(() => {
            axios
              .get(axios.defaults.baseURL + apis.GET_REWARDS, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
              .then((res) => {
                console.log("getRewards res--", res);
                commit("SET_REWARDS", res.data);
    
                // resolve(res);
              })
              .catch((err) => {
                console.log("getRewards err", err);
                dispatch("checkErrorAndSendToast", [err.response, "error"]);
            
              });
          });
      },

      getRecentFinds({ commit }) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.RECENT_FINDS)
                .then((res) => {
                    console.log('getRecentFinds---res !!', res)
                    if(Array.isArray(res.data)){
                        commit('SET_RECENT_FIND_DATA', res.data)
                    }
                    else{
                       commit('SET_RECENT_FIND_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getRecentFinds err', err)
                })
        // })
      },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };